import React from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { SwiperSlide } from "swiper/react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import { getAccentColor } from "@utils/V2/color"
getAccentColor

SwiperCore.use([Navigation, Pagination])

export type CategorySlideProps = {
  categoryHeadingText: string
  color?: Color.Charcoal | Color.White
  headingText?: string
  descriptionText?: string
  buttonText?: string
  buttonUrl?: string
  image?: string
  imageAlt?: string
}

const CategorySlide = ({
  color = Color.Charcoal,
  headingText,
  descriptionText,
  buttonText,
  buttonUrl,
  image,
  imageAlt,
}: CategorySlideProps) => {
  return (
    <SwiperSlide>
      <div className="flex flex-col-reverse gap-12-v2 md-v2:flex-row md-v2:gap-24-v2 lg-v2:gap-32-v2 xl-v2:gap-40-v2">
        <div className="content-center pr-48-v2 md-v2:w-1/2">
          {headingText && (
            <Typography
              size="h4"
              font="grotesk"
              weight="book"
              color={color}
              text={headingText}
              element="h4"
            />
          )}
          {descriptionText && (
            <Typography
              size="body-lg"
              font="grotesk"
              weight="book"
              color={color}
              className="pt-16-v2"
              text={descriptionText}
            />
          )}
          {buttonText && buttonUrl && (
            <PillButton
              style="solid"
              size="medium"
              className="mt-32-v2 w-fit"
              color={Color.Blue}
              text={buttonText}
              linkUrl={buttonUrl}
            />
          )}
        </div>
        {image && (
          <div className="flex content-center justify-center md-v2:w-1/2">
            <GatsbyStoryblokImage
              aspectRatio="4:3"
              image={image}
              alt={imageAlt || "Category slide image"}
              quality={80}
              width={1000}
              className="sm-v2:w-2/3 md-v2:w-full"
              testId="category-slide-image"
            />
          </div>
        )}
      </div>
    </SwiperSlide>
  )
}

CategorySlide.displayName = "SwiperSlide"

export default CategorySlide
